import $ from "cash-dom";

export const onClientEntry = () => {
  window.onload = () => {
    cookies();
    mobileMenu();
    lazyloadVideo();
  }
}

export const onRouteUpdate = () => {
  lazyloadVideo();
  mobileMenu();
}

function mobileMenu() {
  $('.js-burger').on('click', function() {
    $('.js-mobile-menu').addClass('is-open');
  });

  $('.js-close, .mobile-menu__list a, .mobile-menu__list span, .mobile-menu__button a').on('click', function() {
    $('.js-mobile-menu').removeClass('is-open');
  });
}

function cookies() {
  $("#confirm-cookie").on('click', function() {
    createCookie('cookie_confirmed', 1, 30, '/');
    $("#cookie-bar").removeClass('is-show');
  });

  if (readCookie('cookie_confirmed') == null) {
    $("#cookie-bar").addClass('is-show');
  }
}

function createCookie(name, value, days, path) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();
  }
  else expires = "";
  document.cookie = name + "=" + value + expires + "; path=" + path;
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function lazyloadVideo() {
  const lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

  if ("IntersectionObserver" in window) {
    var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source];
            if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function(lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
}
